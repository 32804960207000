import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Screen from './components/Screen';
import Button from './components/Button';
import getEmail from './query-string-helpers/getEmail';
import RegistrationForm from './registration-form/RegistrationForm';
import styles from './App.module.scss';

export default class app extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loadingSpinner: false };
  }

  componentDidMount() {
    let email = getEmail();

    if (email) {
      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: email,
            email: email
          }
        });
      }
    } else {
      window.Intercom('boot', {
        /* eslint-disable */
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      });
      if (window.pendo) {
        window.pendo.initialize();
      }
    }
  }

  loadingSpinner = (show) => {
    this.setState({ loadingSpinner: show });
  };

  handleWelcomeBack = () => {
    window.location = `${process.env.REACT_APP_AU_APP_URL}${window.location.search}`;
  };

  render() {
    return (<div className={styles.appContainer}>
      {this.state.loadingSpinner &&
        <div className={styles.loadingSpinner}>
          <div className={`${styles.spinnerCenter} spinner-border`} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      <Switch>
        <Route path="/confirm">
          <Screen>
            <h1 className={styles.screenHeader}>Confirm your email address</h1>
            <p>We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, click the confirmation link.</p>
            <p>If you do not receive a confirmation email, check your spam folder.</p>
            <p>If you need assistance, please email <a href="mailto:support@plexus.co">support@plexus.co</a>.</p>
            <p>Thanks for creating your account. You can now close this tab in your browser.</p>
          </Screen>
        </Route>
        <Route path="/organisation-exists">
          <Screen>
            <h1 className={styles.screenHeader}>One more step...</h1>
            <p>Looks like your organisation already has an account.</p>
            <p>
              You'll need to contact the owner for access.
              If you need help with this, contact <a href="mailto:support@plexus.co">support@plexus.co</a>.
            </p>
          </Screen>
        </Route>
        <Route path="/welcome">
          <Screen>
            <h1 className={styles.screenHeader}>Welcome back</h1>
            <p>Looks like you're a Plexus Gateway customer.</p>
            <p>Log in to access Promotion Wizard.</p>
            <Button className={styles.screenFooter} onClick={this.handleWelcomeBack}>Log in</Button>
          </Screen>
        </Route>
        <Route path="/error">
          <Screen>
            <h1 className={styles.screenHeader}>An error occurred</h1>
            <p>An unexpected error occurred.</p>
            <p>Please try again later, or email <a href="mailto:support@plexus.co">support@plexus.co</a>.</p>
            <a href={`${process.env.PUBLIC_URL}/${window.location.search}`}>
              <Button className={styles.screenFooter}>Try again</Button>
            </a>
          </Screen>
        </Route>
        <Route path="/email-error">
          <Screen>
            <h1 className={styles.screenHeader}>An error occurred</h1>
            <p>We were unable to send an email with a confirmation link to your email address.</p>
            <p>Please ensure there are no typos in your email and try again.</p>
            <p>If the problem persists, contact <a href="mailto:support@plexus.co">support@plexus.co</a>.</p>
            <a href={`${process.env.PUBLIC_URL}/${window.location.search}`}>
              <Button className={styles.screenFooter}>Try again</Button>
            </a>
          </Screen>
        </Route>
        <Route path="/">
          <Screen>
            <RegistrationForm loadingSpinner={this.loadingSpinner} />
          </Screen>
        </Route>
      </Switch>
    </div >);
  }
}
