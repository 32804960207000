import React from 'react';
import PropTypes from 'prop-types';
import URLSearchParams from '@ungap/url-search-params';
import fromEntries from '@ungap/from-entries';
import * as EmailValidator from 'email-validator';
import createPasswordPolicy from 'password-sheriff';

import PasswordLabel from '../passwords/PasswordLabel';
import { submit, validateEmail } from './submit';
import Button from '../components/Button';
import Input from './Input';
import styles from './RegistrationForm.module.scss';

const FIELDS = {
  'email': {
    label: 'Email',
    type: 'email',
    queryParam: 'email',
    maxLength: '255'
  },
  'first_name': {
    label: 'First name',
    type: 'text',
    queryParam: 'first_name',
    maxLength: '255'
  },
  'last_name': {
    label: 'Last name',
    type: 'text',
    queryParam: 'last_name',
    maxLength: '255'
  },
  'phone_number': {
    label: 'Contact number',
    type: 'tel',
    queryParam: 'last_name',
    maxLength: '30'
  },
  'password': {
    label: <PasswordLabel passwordPolicy="excellent" />,
    type: 'password',
    passwordPolicy: 'excellent',
    showValidationBorder: true
  },
  'marketing_opt_in': {
    label: 'I\'m interested in receiving relevant insights, research and analysis from time to time',
    type: 'checkbox',
    defaultValue: true
  },
  'terms_and_conditions_accepted': {
    label: <React.Fragment>
      I accept the Promotion Wizard <a target="_blank" href="https://plexus.co/terms-of-use" rel="noopener noreferrer">terms of use</a>
    </React.Fragment>,
    type: 'checkbox'
  },
  'country': {
    label: 'Country',
    type: 'text',
    queryParam: 'country',
    defaultValue: 'AU',
    hidden: true
  }
};

function getQueryParameters() {
  // I had difficulty mocking window.location.search, so we cannot use it here
  const queryString = (/\?(.*)/.exec(window.location.href) || [undefined, ''])[1];

  return new URLSearchParams(queryString);
}

function getFieldValue(fieldName, rawValue) {
  const { type } = FIELDS[fieldName];

  if (type === 'checkbox') {
    return rawValue ? true : false;
  } else if (type === 'text' || type === 'email' || type === 'tel') {
    return typeof (rawValue) === 'string' ? rawValue.trim() : '';
  } else if (type === 'password') {
    return typeof (rawValue) === 'string' ? rawValue : '';
  }
}

function getFieldError(fieldName, value) {
  if (fieldName === 'email' && !EmailValidator.validate(value)) {
    return 'Enter a valid email address';
  } else if ((fieldName === 'first_name' || fieldName === 'last_name') && !value) {
    return 'This field is required';
  } else if (fieldName === 'terms_and_conditions_accepted' && !value) {
    return 'Acceptance of the terms of use is required to continue';
  } else if (FIELDS[fieldName].passwordPolicy && !createPasswordPolicy(FIELDS[fieldName].passwordPolicy).check(value)) {
    return 'Enter a valid password';
  } else {
    return null;
  }
}

function getFieldState(fieldName, rawValue, isEditing = false) {
  const value = getFieldValue(fieldName, rawValue);
  const error = getFieldError(fieldName, value);

  return isEditing ? { value: rawValue, error } : { value, error };
}

export function getInitialState() {
  const queryParameters = getQueryParameters();
  const fieldStates = fromEntries(Object.keys(FIELDS).map(fieldName => {
    const { queryParam } = FIELDS[fieldName];

    let fieldState = getFieldState(fieldName, queryParameters.get(queryParam));
    if (!fieldState['value'] && FIELDS[fieldName]['defaultValue']) {
      fieldState['value'] = FIELDS[fieldName]['defaultValue'];
    }

    return [fieldName, fieldState];
  }));

  return {
    ...fieldStates,
    disabled: false,
    hideValidationErrors: true
  };
}

export function getStateAfterFieldChange(state, fieldName, rawValue, isEditing = false) {
  return {
    ...state,
    [fieldName]: getFieldState(fieldName, rawValue, isEditing)
  };
}

export function hasError(state) {
  return Object.keys(FIELDS).filter(fieldName => state[fieldName].error).length ? true : false;
}

export async function handleSubmit(state, setState) {
  if (hasError(state)) {
    setState({ ...state, hideValidationErrors: false });
  } else {
    setState({ ...state, hideValidationErrors: false, disabled: true });

    const fieldValues = fromEntries(Object.keys(FIELDS).map(fieldName =>
      [fieldName, state[fieldName].value]
    ));

    await submit(fieldValues);

    setState({ ...state, disabled: false });
  }
}

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = getInitialState();
    this.validateUserOrganisation();
  }

  validateUserOrganisation() {
    if (this.state && this.state.email && this.state.email.value &&
      this.state.country && this.state.country.value) {
      this.props.loadingSpinner(true);
      validateEmail({
        email: this.state.email.value,
        country: this.state.country.value
      })
        .finally(() => {
          this.props.loadingSpinner(false);
        });
    }
  }

  handleFieldChange = (fieldName, rawValue) => {
    this.setState(getStateAfterFieldChange(this.state, fieldName, rawValue, true));
  };

  handleFieldBlur = (fieldName, rawValue) => {
    this.setState(getStateAfterFieldChange(this.state, fieldName, rawValue));
  };

  handleSubmit = async () => {
    this.props.loadingSpinner(true);
    handleSubmit(this.state, state => this.setState(state))
      .finally(() => {
        this.props.loadingSpinner(false);
      });
  };

  render() {
    const { disabled, hideValidationErrors } = this.state;
    const submitDisabled = disabled || (hasError(this.state) && !hideValidationErrors);

    return <div className={styles.root}>
      <h1 className={styles.header}>Create your account</h1>
      {
        Object.keys(FIELDS).map(fieldName => {
          const { [fieldName]: { value, error } } = this.state;
          const field = FIELDS[fieldName];

          return <Input
            {...field}
            hideValidationMessage={hideValidationErrors}
            showValidationBorder={!hideValidationErrors && field.showValidationBorder}
            name={fieldName}
            key={fieldName}
            value={value}
            error={error}
            disabled={disabled}
            onChange={this.handleFieldChange}
            onBlur={this.handleFieldBlur}/>;
        })
      }
      <Button
        disabled={submitDisabled}
        onClick={this.handleSubmit}>
        Get started
      </Button>
    </div>;
  }
}

RegistrationForm.propTypes = {
  loadingSpinner: PropTypes.func
};

export default RegistrationForm;
